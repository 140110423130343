import { Fragment } from "react";

import Table from "react-bootstrap/Table";

import { FaInfo } from "react-icons/fa";

import Message from "../../components/Message";
import Loader from "../../components/Loader";
import TableBoolean from "../../components/TableBoolean";

import { useGetOrdersQuery } from "../../slices/ordersApiSlice";
import { shortDate } from "../../utils/timeUtils";
import { showLargeNumber } from "../../utils/cartUtils";
import TableLink from "../../components/TableLink";

const OrderListScreen = () => {
  const { data: orders, isLoading, error } = useGetOrdersQuery();

  return (
    <Fragment>
      <h1>Orders</h1>

      {isLoading && <Loader />}

      {!isLoading && error && (
        <Message variant={"danger"}>
          {error?.data?.message || error.error}
        </Message>
      )}

      {!isLoading && !error && (
        <Table striped bordered hover responsive className="table-sm m-auto">
          <thead>
            <tr>
              <th>ID</th>
              <th>USER</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>PAID</th>
              <th>DELIVERED</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>{order.user && order.user.name}</td>
                <td>{shortDate(order.createdAt)}</td>
                <td className="text-end pe-3">
                  {showLargeNumber(order.totalPrice)}
                </td>
                <td>
                  <TableBoolean isDone={order.isPaid} date={order.paidAt} />
                </td>
                <td>
                  <TableBoolean
                    isDone={order.isDelivered}
                    date={order.deliveredAt}
                  />
                </td>
                <td>
                  <TableLink
                    url={`/order/${order._id}`}
                    classes={"btn btn-sm"}
                    variant={"primary"}
                    content={<FaInfo />}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Fragment>
  );
};

export default OrderListScreen;

import { Fragment } from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const ShippingAddress = ({ address, city, postalCode, country, formatted }) => {
  return (
    <Fragment>
      {formatted ? (
        <Fragment>
          <Row>
            <Col lg={2} md={4} sm={4} className="me-2">
              <strong>Address:</strong>
            </Col>
            <Col lg={4} md={5} sm={6}>
              {address}
            </Col>
          </Row>
          <Row>
            <Col lg={2} md={4} sm={4} className="me-2">
              <strong>City:</strong>
            </Col>
            <Col lg={4} md={5} sm={6}>
              {postalCode}, {city}
            </Col>
          </Row>

          <Row>
            <Col lg={2} md={4} sm={4} className="me-2">
              <strong>Country:</strong>
            </Col>
            <Col lg={4} md={5} sm={6}>
              {country}
            </Col>
          </Row>
        </Fragment>
      ) : (
        <>
          {address}, {postalCode} {city}, {country}
        </>
      )}
    </Fragment>
  )
}

ShippingAddress.defaultProps = {
  formatted: false,
}

export default ShippingAddress

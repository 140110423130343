import Image from "react-bootstrap/Image"
import Table from "react-bootstrap/Table"
import { showLargeNumber } from "../utils/cartUtils"

const ItemsTable = ({ items }) => {
  return (
    <Table striped hover responsive className="table-sm">
      <thead>
        <tr>
          <th style={{ width: "8%" }}></th>
          <th>ITEM</th>
          <th>QTY</th>
          <th>PRICE</th>
          <th>TOTAL</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={item._id}>
            <td className="img-fluid">
              <Image
                className="border border-1"
                src={item.image}
                alt={item.name}
                fluid
                rounded
              />
            </td>
            <td className="align-middle">{item.name}</td>
            <td className="align-middle">{item.quantity}</td>
            <td className="align-middle">{showLargeNumber(item.price)}</td>
            <td className="align-middle">
              {showLargeNumber(item.quantity * item.price)}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default ItemsTable

import { useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Card from "react-bootstrap/Card"

import { toast } from "react-toastify"
import Message from "../components/Message"
import Loader from "../components/Loader"
import CheckoutSteps from "../components/CheckoutSteps"

import { useCreateOrderMutation } from "../slices/ordersApiSlice"
import { clearCartItems } from "../slices/cartSlice"
import { showLargeNumber } from "../utils/cartUtils"
import InformationSummary from "../components/InformationSummary"
import ItemsTable from "../components/ItemsTable"

const PlaceOrderScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  const [createOrder, { isLoading, error }] = useCreateOrderMutation()

  const shippingInfo = [
    { type: "Address", content: cart.shippingAddress.address },
    {
      type: "City",
      content: `${cart.shippingAddress.postalCode}, ${cart.shippingAddress.city}`,
    },
    { type: "Country", content: cart.shippingAddress.country },
  ]

  const paymentInfo = [{ type: "Method", content: cart.paymentMethod }]

  useEffect(() => {
    if (!cart.shippingAddress.address) {
      navigate("/shipping")
    } else if (!cart.paymentMethod) {
      navigate("/payment")
    }
  }, [navigate, cart.paymentMethod, cart.shippingAddress.address])

  async function placeOrderHandler() {
    try {
      const response = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      }).unwrap()

      dispatch(clearCartItems())
      navigate(`/order/${response._id}`)
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <Fragment>
      <CheckoutSteps step1 step2 step3 step4 />

      <Row className="my-2">
        <Col lg={8} md={7}>
          <InformationSummary header="Shipping" information={shippingInfo} />
          <InformationSummary
            header="Payment Method"
            information={paymentInfo}
          />
        </Col>

        <Col lg={4} md={5}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Items:</Col>
                  <Col>{showLargeNumber(cart.itemsPrice)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Shipping:</Col>
                  <Col>${cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Tax:</Col>
                  <Col>{showLargeNumber(cart.taxPrice)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col>{showLargeNumber(cart.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                {error && (
                  <Message variant="danger">
                    {error?.data?.message || error.error}
                  </Message>
                )}
              </ListGroup.Item>

              <ListGroup.Item>
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>

                {isLoading && <Loader />}
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row>
        <ListGroup variant="flush">
          <ListGroup.Item>
            <h2>Order Items</h2>
          </ListGroup.Item>
        </ListGroup>

        {cart.cartItems.length > 0 ? (
          <Row>
            <ItemsTable items={cart.cartItems} />
            {/* <Table striped hover responsive className="table-sm">
              <thead>
                <tr>
                  <th style={{ width: "8%" }}></th>
                  <th>ITEM</th>
                  <th>QTY</th>
                  <th>PRICE</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {cart.cartItems.map((item, index) => (
                  <tr key={item._id}>
                    <td className="img-fluid">
                      <Image
                        className="border border-1"
                        src={item.image}
                        alt={item.name}
                        fluid
                        rounded
                      />
                    </td>
                    <td className="align-middle">{item.name}</td>
                    <td className="align-middle">{item.quantity}</td>
                    <td className="align-middle">
                      {showLargeNumber(item.price)}
                    </td>
                    <td className="align-middle">
                      {showLargeNumber(item.quantity * item.price)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table> */}
          </Row>
        ) : (
          <Message>Your cart is empty</Message>
        )}
      </Row>
    </Fragment>
  )
}

export default PlaceOrderScreen

import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

const Rating = ({ value, text }) => {
  const filled = Math.floor(value);
  const half = value % 1 !== 0 ? 1 : 0;
  const notFilled = Math.floor(5 - value);

  const filledStars = Array(filled).fill(<FaStar />);
  const halfStars = Array(half).fill(<FaStarHalfAlt />);
  const notFilledStars = Array(notFilled).fill(<FaRegStar />);

  const allStars = filledStars.concat(halfStars).concat(notFilledStars);

  return (
    <div className="rating">
      {allStars.map((star, index) => (
        <span key={index}>{star}</span>
      ))}

      <span className="rating-text">{text && text}</span>
    </div>
  );
};

export default Rating;

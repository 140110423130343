import Card from "react-bootstrap/Card"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const InformationSummary = ({ header, information }) => {
  return (
    <Card className="mb-4 px-4 py-1">
      <h2>{header}</h2>
      {information.map((info) => (
        <Row key={info.type}>
          <Col lg={2} md={4} sm={4} className="me-2">
            <strong>{info.type}:</strong>
          </Col>
          <Col lg={4} md={5} sm={6}>
            {info.content}
          </Col>
        </Row>
      ))}
      {/* <ShippingAddress formatted {...cart.shippingAddress} /> */}
    </Card>
  )
}

export default InformationSummary

import { Fragment } from "react";
import { shortDate } from "../utils/timeUtils";
import { FaTimes } from "react-icons/fa";

const TableBoolean = ({ isDone, date }) => {
  return (
    <Fragment>
      {isDone ? shortDate(date) : <FaTimes style={{ color: "red" }} />}
    </Fragment>
  );
};

export default TableBoolean;

import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

const SearchBox = () => {
  const navigate = useNavigate()

  const { keyword: urlKeyword } = useParams()
  const [keyword, setKeyword] = useState(urlKeyword || "")

  async function submitHandler(e) {
    e.preventDefault()

    if (keyword.trim()) {
      navigate(`/search/${keyword}`)
    }

    setKeyword("")
  }

  return (
    <Form onSubmit={submitHandler} className="d-flex mt-4 mb-4 mt-md-0 mb-md-0">
      <Form.Control
        type="search"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        value={keyword}
        placeholder="Search Products.."
        className="mr-sm-2 ml-sm-5"
      ></Form.Control>
      <Button type="submit" variant="outline-light" className="p-2 mx-2">
        Search
      </Button>
    </Form>
  )
}

export default SearchBox

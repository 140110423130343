import { Fragment, useCallback, useEffect, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useGetTopProductsQuery } from "../slices/productsApiSlice";
import Loader from "./Loader";
import Message from "./Message";

const Carousel = () => {
  const { data: products, isLoading, error } = useGetTopProductsQuery();

  const [currentProduct, setCurrentProduct] = useState(0);

  const prevSlide = () => {
    if (products === undefined) {
      return;
    }

    setCurrentProduct((oldProduct) => {
      const result = (oldProduct - 1 + products.length) % products.length;
      return result;
    });
  };
  const nextSlide = useCallback(() => {
    if (products === undefined) {
      return;
    }

    setCurrentProduct((oldProduct) => {
      const result = (oldProduct + 1) % products.length;
      return result;
    });
  }, [products]);

  useEffect(() => {
    let sliderId = setInterval(() => {
      nextSlide();
    }, 5000);
    return () => {
      clearInterval(sliderId);
    };
  }, [nextSlide]);

  return (
    <section className="slider-container">
      {isLoading && <Loader />}

      {!isLoading && error && (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      )}

      {!isLoading && !error && (
        <Fragment>
          {products.map((product, productIndex) => {
            const { _id, image, name, description, price } = product;
            return (
              <article
                className="slide"
                style={{
                  transform: `translateX(${
                    100 * (productIndex - currentProduct)
                  }%)`,
                  opacity: productIndex === currentProduct ? 1 : 0,
                  visibility:
                    productIndex === currentProduct ? "visible" : "hidden",
                }}
                key={_id}
              >
                <img src={image} alt={name} className="product-img" />
                <h5 className="name">
                  {name} <span className="price">${price}</span>
                </h5>
                <p className="description">{description}</p>
              </article>
            );
          })}
          <button type="button" className="prev" onClick={prevSlide}>
            <FiChevronLeft size={"4rem"} />
          </button>
          <button type="button" className="next" onClick={nextSlide}>
            <FiChevronRight size={"4rem"} />
          </button>
        </Fragment>
      )}
    </section>
  );
};
export default Carousel;

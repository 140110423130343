import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";

const TableLink = ({ url, classes, variant, content }) => {
  return (
    <LinkContainer to={url}>
      <Button className={classes} variant={variant}>
        {content}
      </Button>
    </LinkContainer>
  );
};

export default TableLink;

import { useEffect, Fragment } from "react"
import { useParams } from "react-router-dom"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import ListGroup from "react-bootstrap/ListGroup"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"
import Card from "react-bootstrap/Card"
import Table from "react-bootstrap/Table"

import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js"

import { toast } from "react-toastify"
import { useSelector } from "react-redux"

import Message from "../components/Message"
import Loader from "../components/Loader"

import {
  useGetOrderDetailsQuery,
  usePayOrderMutation,
  useGetPayPayClientIdQuery,
  useDeliverOrderMutation,
} from "../slices/ordersApiSlice"
import { readableDate } from "../utils/timeUtils"
import ShippingAddress from "../components/ShippingAddress"
import { showLargeNumber } from "../utils/cartUtils"

const OrderScreen = () => {
  const { id: orderId } = useParams()

  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId)

  const [payOrder, { isLoading: loadingPay }] = usePayOrderMutation()
  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation()
  const [{ isPending }, paypalDispatch] = usePayPalScriptReducer()
  const {
    data: paypal,
    isLoading: loadingPayPal,
    error: errorPayPal,
  } = useGetPayPayClientIdQuery()
  const { userInfo } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!errorPayPal && !loadingPayPal && paypal.clientId) {
      async function loadPayPalScript() {
        paypalDispatch({
          type: "resetOptions",
          value: {
            "client-id": paypal.clientId,
            currency: "USD",
          },
        })
        paypalDispatch({
          type: "setLoadingStatus",
          value: "pending",
        })
      }

      if (order && !order.isPaid) {
        if (!window.paypal) {
          loadPayPalScript()
        }
      }
    }
  }, [order, paypal, paypalDispatch, loadingPayPal, errorPayPal])

  // async function onApproveTest() {
  //   await payOrder({ orderId, details: { payer: {} } })
  //   refetch()
  //   toast.success('Payment successful')
  // }

  function onApprove(data, actions) {
    return actions.order.capture().then(async function (details) {
      try {
        await payOrder({ orderId, details }).unwrap()
        refetch()
        toast.success("Payment successful")
      } catch (err) {
        toast.error(err?.data?.message || err.message)
      }
    })
  }

  function onError(err) {
    toast.error(err.message)
  }

  function createOrder(data, actions) {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              value: order.totalPrice,
            },
          },
        ],
      })
      .then((orderId) => orderId)
  }

  async function deliverOrderHandler() {
    try {
      await deliverOrder(orderId)
      refetch()
      toast.success("Order delivered")
    } catch (err) {
      toast.error(err?.data?.message || err.message)
    }
  }

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <Message variant="danger">{error?.data?.message || error.error}</Message>
    )
  }

  return (
    <Fragment>
      <h1 className="my-2">
        Order-ID <span className="order-id">{order._id}</span>
      </h1>
      <hr />

      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {order.user.name}
              </p>
              <p>
                <strong>Email: </strong> {order.user.email}
              </p>
              <p>
                <strong>Address: </strong>
                <ShippingAddress {...order.shippingAddress} />
              </p>
              {order.isDelivered ? (
                <Message variant={"success"}>
                  Delivered on {readableDate(order.deliveredAt)}
                </Message>
              ) : (
                <Message variant={"danger"}>Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method:</strong> {order.paymentMethod}
              </p>
              {order.isPaid ? (
                <Message variant={"success"}>
                  Paid on {readableDate(order.paidAt)}
                </Message>
              ) : (
                <Message variant={"danger"}>Not Paid</Message>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Items</Col>
                  <Col>{showLargeNumber(order.itemsPrice)}</Col>
                </Row>

                <Row>
                  <Col>Shipping</Col>
                  <Col>${order.shippingPrice}</Col>
                </Row>

                <Row>
                  <Col>Tax</Col>
                  <Col>{showLargeNumber(order.taxPrice)}</Col>
                </Row>

                <Row>
                  <Col>Total</Col>
                  <Col>{showLargeNumber(order.totalPrice)}</Col>
                </Row>
              </ListGroup.Item>

              {/* PAY ORDER PLACEHOLDER */}
              {!order.isPaid && (
                <ListGroup.Item>
                  {loadingPay || isPending ? (
                    <Loader />
                  ) : (
                    <div>
                      {/* TEST BUTTON */}
                      {/* <Button
                        onClick={onApproveTest}
                        style={{ marginBottom: '10px' }}
                      >
                        Test Pay Order
                      </Button> */}

                      <div>
                        <PayPalButtons
                          createOrder={createOrder}
                          onApprove={onApprove}
                          onError={onError}
                        ></PayPalButtons>
                      </div>
                    </div>
                  )}
                </ListGroup.Item>
              )}

              {/* MARK AS DELIVERED PLACEHOLDER */}
              {loadingDeliver && <Loader />}

              {userInfo &&
                userInfo.isAdmin &&
                order.isPaid &&
                !order.isDelivered && (
                  <ListGroup.Item>
                    <Button
                      type="button"
                      className="btn btn-block"
                      onClick={deliverOrderHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>

      <Row>
        <h2>Order Items</h2>
        <Table striped hover responsive className="table-sm">
          <thead>
            <tr>
              <th style={{ width: "8%" }}></th>
              <th>ITEM</th>
              <th>QTY</th>
              <th>PRICE</th>
              <th>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {order.orderItems.map((item, index) => (
              <tr key={item._id}>
                <td className="img-fluid">
                  <Image
                    className="border border-1"
                    src={item.image}
                    alt={item.name}
                    fluid
                    rounded
                  />
                </td>
                <td className="align-middle">{item.name}</td>
                <td className="align-middle">{item.quantity}</td>
                <td className="align-middle">{showLargeNumber(item.price)}</td>
                <td className="align-middle">
                  {showLargeNumber(item.quantity * item.price)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </Fragment>
  )
}

export default OrderScreen

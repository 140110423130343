import { createSlice } from '@reduxjs/toolkit'
import { updateCart } from '../utils/cartUtils'

const emptyState = {
  cartItems: [],
  shippingAddress: {},
  paymentMethod: 'PayPal'
}

const initialState = localStorage.getItem('cart')
  ? JSON.parse(localStorage.getItem('cart'))
  : emptyState

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      // The item to add to the cart
      const item = action.payload

      // Check if the item is already in the cart
      const existItem = state.cartItems.find(
        cartItem => cartItem._id === item._id
      )

      if (existItem) {
        // If exists, update quantity
        state.cartItems = state.cartItems.map(cartItem => {
          return cartItem._id === existItem._id ? item : cartItem
        })
      } else {
        // If not exists, add new item to cartItems
        state.cartItems = [...state.cartItems, item]
      }

      // Update the prices and save to storage
      return updateCart(state, item)
    },
    removeFromCart: (state, action) => {
      state.cartItems = state.cartItems.filter(
        cartItem => cartItem._id !== action.payload
      )

      return updateCart(state)
    },
    saveShippingAddress: (state, action) => {
      state.shippingAddress = action.payload
      return updateCart(state)
    },
    savePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload
      return updateCart(state)
    },
    clearCartItems: (state, action) => {
      state.cartItems = []
      return updateCart(state)
    },
    resetCart: state => {
      state = emptyState
      return updateCart(state)
    }
  }
})

export const {
  addToCart,
  removeFromCart,
  saveShippingAddress,
  savePaymentMethod,
  clearCartItems,
  resetCart
} = cartSlice.actions

export default cartSlice.reducer

export const showLargeNumber = (number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    number
  );

export const getTotalPrice = (cartItems) =>
  cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);

export const getTotalItems = (cartItems) =>
  cartItems.reduce((acc, item) => acc + item.quantity, 0);

export const countArray = (countInStock) => [...Array(countInStock).keys()];

export const addDecimals = (num) => {
  return Number(Math.round(num * 100) / 100).toFixed(2);
};

export const updateCart = (state) => {
  // Calculate the items price
  state.itemsPrice = addDecimals(
    state.cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0)
  );

  // Calculate the shipping price
  state.shippingPrice = addDecimals(state.itemsPrice > 100 ? 0 : 10);

  // Calculate the tax price
  state.taxPrice = addDecimals((0.15 * state.itemsPrice).toFixed(2));

  // Calculate the total price
  state.totalPrice = addDecimals(
    Number(state.itemsPrice) +
      Number(state.shippingPrice) +
      Number(state.taxPrice)
  );

  // Save the cart to localStorage
  localStorage.setItem("cart", JSON.stringify(state));

  return state;
};

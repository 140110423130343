import { Fragment } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Product from "../components/Product";

import Loader from "../components/Loader";
import Message from "../components/Message";
import Paginate from "../components/Paginate";

import { useGetProductsQuery } from "../slices/productsApiSlice";
import { useNavigate, useParams } from "react-router-dom";
import Meta from "../components/Meta";
import Carousel from "../components/Carousel";

const HomeScreen = () => {
  const navigate = useNavigate();
  const { pageNumber, keyword } = useParams();

  const { data, isLoading, error } = useGetProductsQuery({
    pageNumber,
    keyword,
  });

  return (
    <Fragment>
      {isLoading && <Loader />}

      {!isLoading && error && (
        <Message variant="danger">{error.data?.message || error.error}</Message>
      )}

      {!isLoading && !error && (
        <Fragment>
          <Meta />
          {keyword ? (
            <Button onClick={() => navigate(-1)} className="btn btn-light mb-2">
              Go Back
            </Button>
          ) : (
            <Carousel />
          )}

          {keyword ? (
            <h1>Latest Products - {keyword}</h1>
          ) : (
            <h1>Latest Products</h1>
          )}

          <Row>
            {data.products.map((product) => (
              <Col sm={12} md={6} lg={4} xl={3} key={product.name}>
                <Product product={product} />
              </Col>
            ))}
          </Row>

          <Paginate pages={data.pages} page={data.page} keyword={keyword} />
        </Fragment>
      )}
    </Fragment>
  );
};

export default HomeScreen;
